import React from 'react'
import feliz from '../../assets/feliz.png'
import logo from '../../assets/logo-blanco.png'
import './footer.css'

export const Footer = () => {
    return (
        <>
            <div className="container-fluid-footer">
                <div className="container">
                    <div className="row">

                    </div>
                    <div className='row three-section'>
                        <div className='col-md-4 col-sm-12 col-xs-12 text-center mt-5'>
                            <div className='col-md-12 col-sm-12 col-xs-12 text-center'>
                                <img className='logo-footer' src={logo} alt="logo" />
                            </div>
                            <div className='mt-4'>
                                <a className='text-white'>Somos una empresa cercana, con una alta adaptabilidad a los requerimientos de nuestros clientes.</a>
                            </div>

                        </div>
                        <div className='col-md-4 col-sm-12 col-xs-12 text-center mt-4'>
                            <div className='mb-4'>
                                <i className='material-icons'>location_on</i>
                                <h4 className='direccion-footer text-white'>Los Ángeles, Chile.</h4>
                                <h5 className='sub-dir text-white'>Dirección</h5>
                            </div>

                            <div className='mb-4'>
                                <i className='material-icons'>mail_outline</i>
                                <h4 className='contacto-footer text-white'>contacto@econotrans.cl</h4>
                                <h5 className='sub-num text-white'>Email</h5>
                            </div>

                            <div className='mb-4'>
                                <i className='material-icons'>phone</i>
                                <h4 ><a className='numero-footer text-white' href="tel:+569 94788521">+569 94788521</a></h4>
                                <h5 className='sub-fon text-white'>Teléfono</h5>
                            </div>

                        </div>
                        <div className='col-md-4 col-sm-12 col-xs-12 text-center mt-4'>
                            <div>
                                <h3 className='text-white'>Nuestras Redes</h3>
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <a className="nav-link text-white mx-1 text-center" href="https://www.mundomudanzas.cl/empresas/econotrans" target="_blank"><i className="fab fa-maxcdn fa-2x"></i></a>
                                <a className="nav-link text-white mx-1" href="https://amarillas.emol.com/empresa/32285/econotrans-mudanzas#profile" target="_blank"><i className="fab fa-amilia fa-2x"></i></a>
                                <a className="nav-link text-white mx-1" href="https://www.facebook.com/Econotransla" target="_blank"><i className="fab fa-facebook-f fa-2x"></i></a>
                                <a className="nav-link text-white mx-1" href="https://www.instagram.com/econotrans_mudanzas/" target="_blank"><i className="fab fa-instagram fa-2x"></i></a>
                                <a className="nav-link text-white mx-1" href="https://api.whatsapp.com/send?phone=56994788521" target="_blank"><i className="fab fa-whatsapp fa-2x"></i></a>
                            </div>
                            <div className='col-md-12 col-sm-12 col-xs-12 text-center mt-5 text-white'>
                                <img className='feliz' src={feliz} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <hr className='linea mt-4' />
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <p className='derechos text-white'>Copyright 2023 © ECONOTRANS Mudanzas Ltda.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
